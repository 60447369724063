@import '@/_styles/import';
.RefinementButtons {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 93px;
  padding: 20px 30px;

  background-color: $white;

  .btn-text {
    margin-right: 15px;
    color: $black;
  }

  &__button {
    width: 240px;
  }

  &.-with-glow::before {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 9999;
    top: -50px;
    right: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 50px;

    background: linear-gradient(0deg, $white, transparent);
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    z-index: $z-mobile-refinement-filter-buttons;
    bottom: 0;

    width: 100vw;
    padding-right: 25px;
    padding-left: 25px;

    &.-with-reset {
      justify-content: space-between;
    }

    &__button {
      width: 140px;
    }
  }
}
